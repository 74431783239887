/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { ChangeEvent, Component, ReactElement } from "react";
import { InitialStateType } from "../../app/store/rootType";
import { connect, ConnectedProps } from "react-redux";
import {
  getAllStaticQuestionStateLinkIdsController,
  getHierarchyModelController,
} from "../../app/store/staticQuestions/controller";
import AccordionTree, {
  ApiPropertyNode,
  ApiTypeNode,
} from "../accordionTree/accordionTree";
import { Form, Spinner } from "react-bootstrap";

import "./hierarchy.css";
import { isTypeOf } from "../../util/typeGuards";
import { JsonHierarchy } from "../../types/customTypes/ratModels";

interface HierarchyProps {
  // selectSQLob: (lob: string) => void;
  currentState: string;
  currentLOB: string;
  selectedRatingData: string;
  selectRatingData: (
    apiPropertyName: string,
    apiPropertyType: string,
    ratingDataId: string,
    lineOfBusiness: string,
    selectedNode: ApiTypeNode | ApiPropertyNode | undefined
  ) => void;
  allStatesSelected: boolean;

  showRightPanel: (clicked: boolean) => void;
}

interface HierarchyState {
  searchString: string;
  nodesToShow: ApiTypeNode[];
  allNodes: ApiTypeNode[];
  loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  staticQuestionStateIds: state.staticQuestions.staticQuestionStateLinkIds,
  jsonHierarchyModel: state.staticQuestions.jsonHierarchyModel,
  editedRatingData: state.ratingDataPackets.editedPackets,
});

const mapDispatchToProps = {
  getAllStaticQuestionStateLinksController:
    getAllStaticQuestionStateLinkIdsController,
  getHierarchyModelController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HierarchyProps;

class HierarchyComponent extends Component<Props, HierarchyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchString: "",
      nodesToShow: [],
      allNodes: [],
      loading: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    this.setState({
      loading: true,
    });
    await this.props.getHierarchyModelController();
    await this.getSqStateLinks(this.props.currentState);

    const parentNodes: ApiTypeNode[] = [];

    this.props.jsonHierarchyModel.forEach((x) => {
      const lob = this.getLob(x);
      const apiNode = this.buildTreeNodes(x, lob);
      // console.log(apiNode);
      parentNodes.push(apiNode);
    });
    this.setState({
      ...this.state,
      nodesToShow: parentNodes,
      allNodes: parentNodes,
      loading: false,
    });
  };

  componentDidUpdate = async (
    prevProps: Props,
    prevState: HierarchyState
  ): Promise<void> => {
    if (
      prevState.searchString !== this.state.searchString ||
      prevProps.currentState !== this.props.currentState
    ) {
      this.setState({
        loading: true,
      });
      await this.getSqStateLinks(this.props.currentState);
      this.searchNodes();
    }
  };

  getLob = (node: JsonHierarchy): string => {
    switch (node.typeName) {
      case "PersonalAutoRateRequestV1": {
        return "PERSONAL_AUTO";
      }
      case "HomeownersRateRequestV1": {
        return "HOMEOWNERS";
      }
      case "RentersRateRequestV1": {
        return "RENTERS";
      }
      case "CondominiumRateRequestV1": {
        return "CONDO";
      }
      case "PersonalPackageRateRequestV1": {
        return "PERSONAL_PACKAGE";
      }
      // case "CommercialRateRequestV1": { return "COMMERCIAL_RATE_REQUEST"; }
      case "CommercialRateRequestV1": {
        return "COMMERCIAL_PACKAGE";
      }
      default: {
        return "";
      }
    }
  };

  getSqStateLinks = async (state: string): Promise<void> => {
    if (
      !this.props.allStatesSelected &&
      this.props.staticQuestionStateIds[state] == null
    ) {
      await this.props.getAllStaticQuestionStateLinksController(state);
    }
  };

  keyExists = (typeNode: ApiTypeNode, key: string): boolean => {
    for (const childNode of typeNode.nodes) {
      if (childNode.key === key) {
        return true;
      }
    }

    return false;
  };

  // This function will recursively traverse the FRD to build it based on a tree structure
  buildTreeNodes = (jsonHierarchy: JsonHierarchy, lob: string): ApiTypeNode => {
    const typeNode: ApiTypeNode = {
      key: jsonHierarchy.jPath == null ? "" : jsonHierarchy.jPath,
      type: jsonHierarchy.typeName,
      label:
        jsonHierarchy.typeName == null || jsonHierarchy.typeName === ""
          ? ""
          : this.createTypeLabel(jsonHierarchy),
      id: jsonHierarchy.jPath == null ? "" : jsonHierarchy.jPath,
      nodes: [],
      show: undefined,
      isVisible: true,
      sortOrder: 0,
      apiPath: jsonHierarchy.apiPath,
      isArray: jsonHierarchy.isArray,
      annotation:
        jsonHierarchy.annotation == null ? "" : jsonHierarchy.annotation,
    };

    jsonHierarchy.children
      .filter((x) => x.children !== null && x.children.length !== 0)
      .forEach((x) => {
        typeNode.nodes.push(this.buildTreeNodes(x, lob));
      });

    const childProps = jsonHierarchy.children.filter(
      (x) => x.children == null || x.children.length === 0
    );

    childProps.forEach((x) => {
      const childNode: ApiPropertyNode = {
        key: x.jPath,
        label:
          x.propertyName === undefined || x.propertyName === null
            ? ""
            : x.propertyName,
        id: x.jPath,
        ratingDataId:
          x.ratingDataId === undefined || x.ratingDataId === null
            ? ""
            : x.ratingDataId,
        tag: x.tag === undefined || x.tag === null ? "" : x.tag,
        type: x.typeName === undefined || x.typeName === null ? "" : x.typeName,
        lineOfBusiness: lob,
        isArray: x.isArray,
        apiPath: x.apiPath,
        annotation:
          x.annotation === undefined || x.annotation === null
            ? "No notes"
            : x.annotation,
      };

      if (!this.keyExists(typeNode, childNode.key)) {
        typeNode.nodes.push(childNode);
      }
    });
    return typeNode;
  };

  createTypeLabel = (jsonHierarchy: JsonHierarchy): string => {
    // If we're on a child, return the property name.
    if (jsonHierarchy.propertyName !== jsonHierarchy.typeName) {
      return jsonHierarchy.propertyName;
    }
    // Otherwise, expand the type name into a friendly readable.
    let name: string = jsonHierarchy.typeName
      .replace("V1", "")
      .replace(/([A-Z])/g, " $1")
      // Theres a regex way to do this, but I didnt make it work.
      .replace("' O W N E R'", "'OWNER'")
      .replace("' T E N A N T'", "'TENANT'")
      .trim();
    const nameArray: string[] = name.split(" ");
    name =
      jsonHierarchy.apiPath.includes("CoverageV1") &&
      nameArray[0] + nameArray[1] === "PersonalAuto"
        ? name.replace("Personal Auto ", "")
        : name;
    name =
      nameArray[0] === "Property"
        ? name.replace(name.split(" ")[0] + " ", "")
        : name;

    return name;
  };

  createTypeLabel_deleteme = (jsonHierarchy: JsonHierarchy): string => {
    let name: string = jsonHierarchy.typeName
      .replace("V1", "")
      .replace(/([A-Z])/g, " $1")
      .trim();
    name =
      name === "Generic Coverage" &&
      jsonHierarchy.propertyName === "rejectedCoverages"
        ? (name = "Generic Coverage Rejected")
        : name;
    const nameArray: string[] = name.split(" ");
    name =
      jsonHierarchy.apiPath.includes("CoverageV1") &&
      nameArray[0] + nameArray[1] === "PersonalAuto"
        ? name.replace("Personal Auto ", "")
        : name;
    name =
      nameArray[0] === "Property"
        ? name.replace(name.split(" ")[0] + " ", "")
        : name;
    name =
      name === "Heating"
        ? jsonHierarchy.propertyName === "primaryHeating"
          ? "Primary Heating"
          : "Secondary Heating"
        : name;
    name =
      name === "Vehicle Usage"
        ? jsonHierarchy.propertyName === "principalUsage"
          ? "Principal Vehicle Usage"
          : "Occasional Vehicle Usage"
        : name;
    name =
      name === "Phone Number"
        ? jsonHierarchy.propertyName === "fax"
          ? "Fax"
          : "Phone Number"
        : name;
    return name;
  };

  // Recursive search.
  // The idea is that if we hit a match, we don't load any siblings of it (unless they also match)
  // If the match is an API Type and not an API Prop, then load all properties under that API Type, but the API Type should not be expanded in the view
  searchForChildMatch = (
    node: ApiTypeNode | ApiPropertyNode,
    searchString: string,
    currentState: string,
    parentMatchFound: boolean
  ):
    | { node: ApiTypeNode | ApiPropertyNode; childFound: boolean }
    | undefined => {
    let matchFound = false;
    if (isTypeOf<ApiTypeNode>(node, "nodes")) {
      // See if this API Type is a match for the search
      if (
        node.label
          .toLocaleUpperCase()
          .includes(searchString.toLocaleUpperCase()) ||
        node.key.toLocaleUpperCase().includes(searchString.toLocaleUpperCase())
      ) {
        matchFound = true;
      }
      const childNodes: Array<ApiTypeNode | ApiPropertyNode> = [];
      const foundSelf = matchFound;
      node.nodes.forEach((x) => {
        // Search all of the children
        const resultingChild = this.searchForChildMatch(
          x,
          searchString,
          currentState,
          matchFound || parentMatchFound
        );
        if (resultingChild != null) {
          // If a child match is found, expand the node
          if (
            resultingChild.childFound &&
            this.state.searchString.trim() !== ""
          ) {
            node.show = true;
          }
          matchFound = true;
          childNodes.push(resultingChild.node);
          // TODO: Fix this so that you can search API Types when a state is selected
        } else if (
          this.state.searchString.trim() !== "" &&
          this.props.allStatesSelected &&
          (foundSelf || parentMatchFound)
        ) {
          // If the API Type was a match for search (or a parent api type was) then we need to load all children
          childNodes.push(x);
        }
      });
      node.nodes = childNodes;
      if (childNodes.length === 0) {
        node.isVisible = false;
      }
    } else {
      // See if API Prop is a match
      if (
        (node.label
          .toLocaleUpperCase()
          .includes(searchString.toLocaleUpperCase()) ||
          node.key
            .toLocaleUpperCase()
            .includes(searchString.toLocaleUpperCase()) ||
          node.tag
            .toLocaleUpperCase()
            .includes(searchString.toLocaleUpperCase())) &&
        this.isSqStateMatch(currentState, node.ratingDataId)
      ) {
        matchFound = true;
      }
    }
    if (matchFound) {
      return { node: node, childFound: matchFound };
    } else {
      return undefined;
    }
  };

  isSqStateMatch = (state: string, ratingDataId: string): boolean => {
    if (this.props.allStatesSelected) {
      return true;
    }
    const sqStateIds = this.props.staticQuestionStateIds[state];
    if (sqStateIds == null || sqStateIds.length === 0) {
      return true;
    }
    if (sqStateIds.some((id) => id === ratingDataId)) {
      return true;
    }
    return false;
  };

  // This is a recursive deep copy, so we don't modify the original FRD objects with the search functions
  copyNodes = (
    nodes: Array<ApiTypeNode | ApiPropertyNode>,
    setShowFalse: boolean
  ): Array<ApiTypeNode | ApiPropertyNode> => {
    const result: Array<ApiTypeNode | ApiPropertyNode> = [];
    nodes.forEach((x) => {
      if (isTypeOf<ApiTypeNode>(x, "nodes")) {
        const newTypeNode: ApiTypeNode = {
          key: x.key,
          label: x.label,
          id: x.id,
          type: x.type,
          show: setShowFalse ? false : x.show,
          nodes: [],
          isVisible: true,
          sortOrder: 0,
          annotation: x.annotation,
          isArray: x.isArray,
          apiPath: x.apiPath,
        };
        newTypeNode.nodes = this.copyNodes(x.nodes, setShowFalse);
        result.push(newTypeNode);
      } else {
        result.push({
          key: x.key,
          label: x.label,
          id: x.id,
          ratingDataId: x.ratingDataId,
          tag: x.tag,
          type: x.type,
          lineOfBusiness: x.lineOfBusiness,
          annotation: x.annotation,
          isArray: x.isArray,
          apiPath: x.apiPath,
        });
      }
    });
    return result;
  };

  searchNodes = (): void => {
    if (
      this.state.searchString.trim() !== "" ||
      !this.props.allStatesSelected
    ) {
      this.setState({
        loading: true,
      });
      // First, get a copy of all nodes
      const copyNodes: Array<ApiTypeNode | ApiPropertyNode> = this.copyNodes(
        this.state.allNodes,
        true
      );
      const tempNodes: ApiTypeNode[] = [];
      // Search the nodes
      copyNodes.forEach((node) => {
        const tempNode = this.searchForChildMatch(
          node,
          this.state.searchString.trim(),
          this.props.currentState,
          false
        );
        if (
          tempNode !== undefined &&
          isTypeOf<ApiTypeNode>(tempNode.node, "nodes")
        ) {
          tempNodes.push(tempNode.node);
        }
      });
      // Set the state to the resulting search nodes
      this.setState({ ...this.state, nodesToShow: tempNodes });
    } else {
      // Search was cleared. Return the tree to the default
      this.setState({ ...this.state, nodesToShow: this.state.allNodes });
    }
    this.setState({
      loading: false,
    });
  };

  // This is a generic handleChange function. Whatever the name is of the caller, it will update the state variable of the same name
  handleChange = (element: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      [element.target.name]: element.target.value,
    });
  };

  handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.key === "Enter" && event.preventDefault();
  };

  render = (): ReactElement => {
    return (
      <div className="hierarchy-data-box scrollbar-success">
        <Form>
          <Form.Group controlId="formSearch" className="has-search">
            <span className="fa fa-search form-control-search"></span>
            <Form.Control
              type="text"
              placeholder="Type here to filter"
              name="searchString"
              onChange={this.handleChange}
              className="form-control"
              onKeyPress={this.handleEnter}
            />
          </Form.Group>
        </Form>
        {this.state.loading ? (
          <Spinner animation="border" className="loader" />
        ) : (
          <div>
            {this.state.nodesToShow.map((node) => {
              return (
                <AccordionTree
                  key={node.id}
                  parentNode={node}
                  selectRatingData={this.props.selectRatingData}
                  activeRatingData={this.props.selectedRatingData}
                  showRightPanel={this.props.showRightPanel}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };
}

export default connector(HierarchyComponent);
