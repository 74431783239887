/*
 * © 2023 Vertafore, Inc. All rights reserved.
 * Reproduction and distribution without the written permission of Vertafore is prohibited.
 */
import React, { Component, ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { InitialStateType } from "../../app/store/rootType";
import Collapsible from "../collapsible/collapsible";
import ValueListComponent from "../valueList/valueList";
import RulesComponent from "../rules/rules";
// import RatingDataSampleComponent from "../ratingSample/ratingSample";
import "./flatRatingData.css";
import { Button, Spinner, Table } from "react-bootstrap";
import {
  getPacketByRatingDataIdController,
  getPacketByTypeNamePropertyNameController,
  updatePacketController,
} from "../../app/store/ratingDataPacket/controller";
import { MigrationPacket } from "../../types/customTypes/ratModels";
import ConfirmationModal from "./confirmationModal";
import { EntitlementLevel } from "../../types/enums/entitlementLevel";
import { ApiPropertyNode, ApiTypeNode } from "../accordionTree/accordionTree";

interface RatingDataProps {
  selectedRatingData: string;
  selectedNode: ApiTypeNode | ApiPropertyNode | undefined;
  collapseType?: string;
  selectedState: string;
  autoLoadDetail: boolean;
}

export enum Mode {
  Edit = "Edit",
  Preview = "Preview",
  View = "View",
}

interface FlatRatingDataState {
  currentRatingData: MigrationPacket | undefined;
  loading: boolean;
  mode: Mode;
  showSaveModal: boolean;
  showDiscardModal: boolean;
  editedPacket: MigrationPacket | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: InitialStateType) => ({
  ratingDataPackets: state.ratingDataPackets.packets,
  editedRatingDataPackets: state.ratingDataPackets.editedPackets,
  userEntitlement: state.userAuth.userEntitlement,
});

const mapDispatchToProps = {
  getPacketByRatingDataIdController,
  getPacketByTypeNamePropertyNameController,
  updatePacketController,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RatingDataProps;

class RatingDataComponent extends Component<Props, FlatRatingDataState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentRatingData: undefined,
      loading: false,
      mode: Mode.View,
      showSaveModal: false,
      showDiscardModal: false,
      editedPacket: undefined,
    };
  }

  componentDidUpdate = async (previousProps: Props): Promise<void> => {
    if (previousProps.selectedRatingData !== this.props.selectedRatingData) {
      await this.loadRatingData(false);
      this.setState({
        mode: Mode.View,
      });
    }
  };

  componentDidMount = async (): Promise<void> => {
    await this.loadRatingData(false);
  };

  switchToEdit = async (): Promise<void> => {
    this.setState({
      mode: Mode.Edit,
    });
  };

  switchToView = async (): Promise<void> => {
    this.setState({
      mode: Mode.View,
    });
  };

  switchToPreView = async (): Promise<void> => {
    this.setState({
      mode: Mode.Preview,
    });
  };

  handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    await this.saveRatingDataChanges(e.target.id, e.target.value);
  };

  handleSelectChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    await this.saveRatingDataChanges(e.target.id, e.target.value);
  };

  saveRatingDataChanges = async (
    propName: string,
    propValue: string
  ): Promise<void> => {
    const fieldToUpdate = propName.split("_")[1];
    if (this.state.currentRatingData !== undefined) {
      const currRatingDataId = this.state.currentRatingData?.ratingData?.id;
      let ratingDataPacket = this.props.editedRatingDataPackets.find(
        (x) =>
          x.ratingData?.id !== null && x.ratingData?.id === currRatingDataId
      );
      if (ratingDataPacket == null) {
        ratingDataPacket = this.state.currentRatingData;
      }

      if (ratingDataPacket?.ratingData != null) {
        let modifiedRatingData = ratingDataPacket?.ratingData;
        modifiedRatingData = {
          ...modifiedRatingData,
          [fieldToUpdate]: propValue,
        };
        ratingDataPacket = {
          ...ratingDataPacket,
          ratingData: modifiedRatingData,
        };
        await this.props.updatePacketController(ratingDataPacket);
      }
    }
  };

  isCurrentRatingDataEdited = (): boolean => {
    return this.props.editedRatingDataPackets.some(
      (packet) =>
        packet.ratingData?.id === this.state.currentRatingData?.ratingData?.id
    );
  };

  loadRatingData = async (isRequested: boolean): Promise<void> => {
    if (
      this.props.selectedRatingData !== undefined &&
      this.props.selectedRatingData !== ""
    ) {
      // Now, let's check packets for the requested RD.
      let selectedPacket = this.props.ratingDataPackets.find((packet) => {
        return packet.ratingData?.id === this.props.selectedRatingData;
      });

      if (selectedPacket !== undefined) {
        // Found it. Now set it.
        if (this.state.mode === Mode.Edit) {
          const ratingDataToEdit = this.props.editedRatingDataPackets.find(
            (rd) => rd.ratingData?.id === selectedPacket?.ratingData?.id
          );
          if (ratingDataToEdit != null) {
            this.setState({
              ...this.state,
              editedPacket: ratingDataToEdit,
            });
          } else {
            this.setState({ ...this.state, currentRatingData: selectedPacket });
          }
        } else {
          this.setState({ ...this.state, currentRatingData: selectedPacket });
        }
      } else if (this.props.autoLoadDetail || isRequested) {
        if (this.props.selectedRatingData != null) {
          this.setState({ ...this.state, loading: true });
          // If we don't have a packet, let's get the packet by the rating data id.
          await this.props.getPacketByRatingDataIdController(
            this.props.selectedRatingData
          );
          selectedPacket = this.props.ratingDataPackets.find((packet) => {
            return packet.ratingData?.id === this.props.selectedRatingData;
          });
        }
        if (selectedPacket !== undefined) {
          this.setState({
            ...this.state,
            currentRatingData: selectedPacket,
            loading: false,
          });
        } else {
          // TODO: Error handler
          console.log("Unable to retrieve FRD");
        }
      }
    } else {
      this.setState({
        ...this.state,
        currentRatingData: undefined,
        loading: false,
      });
    }
  };

  isUpdated = (fieldName: string, fieldValue: string): boolean => {
    return this.getEditedValue(fieldName, fieldValue) !== fieldValue;
  };

  getEditedValue = (
    fieldName: string,
    fieldValue: string | undefined
  ): string | undefined => {
    const packetToEdit = this.props.editedRatingDataPackets.find(
      (rd) => rd.ratingData?.id === this.state.currentRatingData?.ratingData?.id
    );
    if (packetToEdit?.ratingData != null) {
      const packetRatingData = packetToEdit.ratingData;
      const fieldData = Object.entries(packetRatingData).find((x) =>
        [fieldName].includes(x[0])
      );
      if (fieldData != null && fieldData.length > 1 && fieldData[1] != null)
        return fieldData[1].toString();
    }
    return fieldValue;
  };

  ratingDataDisplay = (): ReactElement => {
    if (this.state.loading) {
      return (
        <div className="spinner">
          {" "}
          <Spinner animation="border" className="spinnerCol" />
        </div>
      );
    }
    if (this.state.currentRatingData?.ratingData != null) {
      return (
        <div className="rating-data-display-container">
          <div>
            {this.props.selectedNode?.isArray === true
              ? "Array of " + this.props.selectedNode?.apiPath
              : this.props.selectedNode?.apiPath}
          </div>
          <div>{this.props.selectedNode?.annotation}</div>

          {this.state.mode === Mode.View ? (
            this.props.userEntitlement ===
            EntitlementLevel.READ_AND_WRITE_ACCESS ? (
              <Button
                className="btn v-btn float-right mb-2"
                onClick={this.switchToEdit}
              >
                <i className="fas fa-edit"></i> Edit
              </Button>
            ) : (
              ""
            )
          ) : this.state.mode === Mode.Edit ? (
            <div className="float-right">
              {this.isCurrentRatingDataEdited() ? (
                <Button
                  className="btn v-btn mb-2 mr-2"
                  onClick={this.switchToPreView}
                >
                  <i className="fa fa-eye"></i> Preview Changes
                </Button>
              ) : (
                ""
              )}
              <Button className="btn v-btn mb-2" onClick={this.switchToView}>
                <i className="fa fa-times"></i> Cancel
              </Button>
            </div>
          ) : this.props.editedRatingDataPackets.length > 0 ? (
            <div className="float-right mb-2">
              <Button className="btn v-btn mr-2" onClick={this.switchToEdit}>
                <i className="fas fa-edit"></i>Return To Edit Mode
              </Button>
              <Button
                className="btn v-btn mr-2"
                onClick={this.showDiscardConfirmationModal}
              >
                <i className="fa fa-trash"></i> Discard All Changes
              </Button>
              <Button
                className="btn v-btn mr-2"
                onClick={this.showSaveConfirmationModal}
              >
                <i className="fas fa-save"></i> Save All Changes
              </Button>
            </div>
          ) : (
            ""
          )}

          <Collapsible
            title="Rating Data"
            collapseType={this.props.collapseType}
          >
            <Table striped>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                  {this.state.mode === Mode.Preview ? (
                    <th>Updated Value</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.entries(this.state.currentRatingData.ratingData).map(
                  ([field, value], id) => {
                    if (field !== "rules" && field !== "values") {
                      return (
                        <tr key={id}>
                          <td className="field-label">{field}</td>
                          <td className="field-value">
                            {this.state.mode === Mode.View ||
                            this.state.mode === Mode.Preview
                              ? value == null
                                ? "N/A"
                                : value.toString()
                              : this.renderField(field, value?.toString())}
                          </td>
                          {this.state.mode === Mode.Preview ? (
                            <td className="field-value">
                              {this.isUpdated(
                                field,
                                value == null ? "N/A" : value?.toString()
                              ) ? (
                                <div className="orangeCol">
                                  {this.getEditedValue(
                                    field,
                                    value?.toString()
                                  )}
                                  <i className="fas fa-edit float-right"></i>
                                </div>
                              ) : (
                                <div>
                                  {value == null ? "N/A" : value?.toString()}
                                </div>
                              )}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    }
                    return null;
                  }
                )}
                <tr title={this.state.currentRatingData.states?.toString()}>
                  <td>States</td>
                  <td>{this.state.currentRatingData.states?.length}</td>
                </tr>
              </tbody>
            </Table>
          </Collapsible>

          <Collapsible
            title="Value Lists"
            collapseType={this.props.collapseType}
          >
            <ValueListComponent
              ratingDataId={this.props.selectedRatingData}
              searchState={
                this.props.selectedState === "All"
                  ? ""
                  : this.props.selectedState
              }
            />
          </Collapsible>
          <Collapsible title="Rules" collapseType={this.props.collapseType}>
            <RulesComponent
              ratingDataId={this.props.selectedRatingData}
              selectedState={
                this.props.selectedState === "All"
                  ? ""
                  : this.props.selectedState
              }
            />
          </Collapsible>
        </div>
      );
    } else if (this.props.selectedRatingData !== "") {
      return (
        <div className="mt-5">
          Rating data not loaded.
          <div className="float-right mb-2">
            {!this.props.autoLoadDetail ? (
              <Button
                className="btn v-btn float-right mb-2"
                onClick={async () => {
                  await this.loadRatingData(true);
                }}
              >
                <i className="fas fa-edit"></i> Load
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>{this.props.selectedNode?.apiPath}</div>
          <div>{this.props.selectedNode?.annotation}</div>
        </div>
      );
    } else {
      return (
        <div className="mt-5">
          Please click on any property to view details.
          <div>{this.props.selectedNode?.apiPath}</div>
          <div>{this.props.selectedNode?.annotation}</div>
        </div>
      );
    }
  };

  renderField = (field: string, value: string | undefined): ReactElement => {
    if (value?.toString() === "true" || value?.toString() === "false") {
      return (
        <select
          defaultValue={value?.toString()}
          id={
            String(this.state.currentRatingData?.ratingData?.id) +
            "_" +
            String(field)
          }
          onChange={this.handleSelectChange}
        >
          <option>true</option>
          <option>false</option>
        </select>
      );
    } else if (field === "dataType") {
      return (
        <select
          defaultValue={value?.toString()}
          id={
            String(this.state.currentRatingData?.ratingData?.id) +
            "_" +
            String(field)
          }
          onChange={this.handleSelectChange}
        >
          <option>BOOLEAN</option>
          <option>DATE</option>
          <option>DATE_AND_TIME</option>
          <option>LIST</option>
          <option>NUMERIC</option>
          <option>TEXT</option>
          <option>TIME</option>
        </select>
      );
    } else {
      return (
        <input
          type="text"
          id={
            String(this.state.currentRatingData?.ratingData?.id) +
            "_" +
            String(field)
          }
          defaultValue={this.getEditedValue(field, value?.toString())}
          className="input-lg"
          onChange={this.handleInputChange}
        />
      );
    }
  };

  render = (): ReactElement => {
    return (
      <div className="rating-data-box  fixed-right">
        <div>{this.ratingDataDisplay()}</div>
        <ConfirmationModal
          closeModal={this.hideModal}
          saveModal={this.state.showSaveModal}
          discardModal={this.state.showDiscardModal}
          switchToView={this.switchToView}
        />
      </div>
    );
  };

  hideModal = (): void => {
    this.setState({
      showSaveModal: false,
      showDiscardModal: false,
    });
  };

  showSaveConfirmationModal = (): void => {
    this.setState({
      showSaveModal: true,
      showDiscardModal: false,
    });
  };

  showDiscardConfirmationModal = (): void => {
    this.setState({
      showSaveModal: false,
      showDiscardModal: true,
    });
  };
}

export default connector(RatingDataComponent);
